<template>
  <div class="container-fluid debate py-5">
    <section>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item waves-effect waves-light">
          <a
            class="nav-link active"
            id="latest-tab"
            data-toggle="tab"
            href="#latest"
            role="tab"
            aria-controls="home"
            aria-selected="false"
            >Latest</a
          >
        </li>
        <li class="nav-item waves-effect waves-light">
          <a
            class="nav-link"
            id="Archive-tab"
            data-toggle="tab"
            href="#Archive"
            role="tab"
            aria-controls="Archive"
            aria-selected="false"
            >Archive</a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade active show"
          id="latest"
          role="tabpanel"
          aria-labelledby="latest-tab"
        >
          <latest-debates />
        </div>
        <div
          class="tab-pane fade"
          id="Archive"
          role="tabpanel"
          aria-labelledby="Archive-tab"
        >
          <archive-debates />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ArchiveDebates from "./Archive-Debates.vue";
import LatestDebates from "./Latest-Debates.vue";
  export default {
  components: { LatestDebates, ArchiveDebates },
};
</script>

<style scoped>
.debate {
  padding-left: 12%;
  padding-bottom: 7em;
  padding-right: 12%;
}
@media  screen and (max-width:1000px) {
 .debate {
  padding-left: 5%;
  padding-bottom: 7em;
  padding-right: 5%;
} 
}
</style>