<template>
  <div class="container-fluid debate py-5">
    <div class="row">

      <div class="col-12 col-md-12 col-lg-12 mb-4 upload">
          <button type="button"  data-toggle="modal" 
          data-target="#exampleModalCenter" @click="verifyUser">Upload Debate Topic</button>
      </div>

      <div class="col-md-7">
        
        <small class="my-5">Published by {{ trendingDebates.author }}</small>
        <h2 class="py-3"><b>{{trendingDebates.topic}}</b></h2>
        <h5><b>Thesis</b></h5>
        <p>{{trendingDebates.body && trendingDebates.body.slice(0,400)}} ... <router-link :to="`debates/${trendingDebates.id}`">read more</router-link>
        </p>

        
        <div class="row align-items-center  justify-content-center">

          <div class="col-lg-12 col-md-12 col-12">
              <div class="">
                <button @click="addLeadOpinion(trendingDebates.id)"  class="download_btn">
                Add Lead Opinion
              </button>
              </div>
        </div>
<!-- 
            <div class="col-lg-6 col-md-6 col-4 likeAnddislike">

                  <button class="iconify like mr-3" >
                    <i class="far fa-thumbs-up"></i>
                  </button>

                  <button
                    class="iconify dislike"
                  >
                  <i class="far fa-thumbs-down"></i>
                  </button>
                
            </div> -->
    
          </div>

      </div>

      <div class="col-md-4 offset-md-1 mt-5">
          <img  :src="`${url()}${trendingDebates.pic}`"
          class="img-responsive"
          alt="">
      </div>

    </div>
    <!-- modal content -->
     <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">New Debate</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <div class="alert alert-success" v-if="success">
                  <p>Thank you for submitting your debate article with us.</p>
                  <p>Our team will review and if it meets our standard, it will be approved</p>
              </div>
              <form v-if="!success">
                <div class="form-group">
                    <label for="topic">Title <strong class="text-danger"> (required)</strong></label>
                    <input type="text" id="topic" class="form-control" placeholder="Enter debate title"  required v-model="title">
                </div>
                <div class="form-group">
                    <label for="sourcecUrl">Source URL<strong class="text-danger"> (required)</strong></label>
                    <input type="url" id="sourceUrl" class="form-control" placeholder="Enter Detabe source url"  required v-model="sourceUrl">
                </div>
                  <div class="form-group">
                    <label for="author">Author<strong class="text-danger"> (required)</strong></label>
                    <input type="text" id="author" class="form-control" placeholder="Enter source of debate Article"  required v-model="author">
                </div>
                <div class="form-group">
                    <label for="createdOn">Created On<strong class="text-danger"> (required)</strong></label>
                    <input type="date" id="createdOn" class="form-control" placeholder="Enter source of debate Article"  required v-model="createdOn">
                </div>
                <div class="form-group">
                  <label for="caption">Caption Image<strong class="text-danger"> (required)</strong></label>
                  <div class="custom-file">
                  <input accept="image/*"  type="file" class="custom-file-input" ref="inputfile" 
                  id="caption" @change="uploadImage" >
                  <label class="custom-file-label" for="validatedCustomFile">
                       <span v-if="picTitle">{{picTitle}}</span>
                      <span v-if="!picTitle">Choose file...</span>
                  </label>
                  <small v-if="error" class="form-text text-danger">your picture size shouldn't be more than 1MB</small>
              </div>
              </div>
                <div class="form-group">
                    <label for="description">Description <strong class="text-danger"> (required)</strong></label>
                    <textarea  name="description" 
                        class="form-control" rows="15" id="description" required placeholder="Enter description" v-model="description">
                    </textarea>
                    <!-- <small class="text-danger">Description should be 100 words or less</small> -->
                </div>
            </form>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button v-if="!success"  @click="addDebate" :disabled="!description || !title" type="submit"
               class="btn btn-primary">
               <div v-if="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="sr-only">Loading...</span>
               </div>
               Submit</button>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import debateAuthors from '../../../helpers/debates'
import {mapGetters} from 'vuex'
import url from '../../../helpers/url.js'

export default {
  data() {
    return {
      title:'',
      description:'',
      pic:'',
      picTitle:'',
      created_by:'',
      sourceUrl:'',
      success:false,
      isLoading:false,
      error:false,
      author:'',
      createdOn:'',
      trendingDebates: {}
    }
  },
  computed: {...mapGetters(['token'])},
  methods: {
    url() {
      return url();
    },
    addLeadOpinion(id){
     
      if(!this.token) {
        return  this.$router.replace(`/redirect?service=login`)
      } 
      this.$router.replace(`/debates/${id}/add_lead_opinion`)
                 
    },
    verifyUser() {
      this.success = null
      this.error = null
      if(!this.token) {
          this.$router.replace(`/redirect?service=login`)
      }        
    },
    uploadImage(event) {
        this.pic= event.target.files
        console.log('picture',this.pic[0].name )
        this.picTitle = this.pic[0].name   
        if(this.pic) {
            let size
            for( let i = 0; i < this.pic.length; i++) {
                size  = Math.round(( this.pic[i].size  / 1024)); 
            }
            if( size > 2048) {
                this.error = true
                this.toast({
                    message:'picture Size is too big',
                    type:'error'
                })
                return;
            }
            
        }
    },
    fetchDebates() {
      this.$store.dispatch('fetchApprovedDebateArticle')
          .then( response => {
              const data = response.data
              this.trendingDebates = data[0];
          })
          .catch(err => {
              console.log(err)
              if(err?.response?.data == undefined) {
              this.toast({
                  message:'Network error. Check network connection and try again.',
                  type:'error'
              })
          }
          })
    },
    addDebate() { 
      this.isLoading = true
      this.error = false
      this.success = false
      const formData = new FormData()
      
      formData.append('topic', this.title)
      formData.append('body', this.description)
      formData.append('author', this.author)
      formData.append('sourceUrl', this.sourceUrl)

        if(this.createdOn){
          let formartDate = new Date(this.createdOn).toISOString();
          formData.append("createdOn", formartDate);
        } else {
          formData.append("createdOn", '');
        }

      if(this.pic) {
            for( let i = 0; i < this.pic.length; i++) {
                formData.append('pic', this.pic[i])
              }
          } else {
              formData.append('pic', '')
          }
      // 
      this.$store.dispatch('addNewDebateArticle',formData)
          .then(response => {
              if(response) {
                  this.success = true
                  this.isLoading = false
                  this.title = null
                  this.description = null
                  this.sourceUrl = null
                  this.createdOn = null
                  this.pic = null
                  this.author = null
                  // this.toast({
                  //     message:'Debate article added successfully.',
                  //     type:'success'
                  // })
              }
                
              if(err?.response?.data == undefined) {
                this.isLoading = false
                  this.toast({
                      message:'An Error occured. Try again',
                      type:'error'
                  })
              }
          })
        },

  },

  created() {
    this.fetchDebates()
  }

};
</script>

<style  scoped>
.upload{
  display: flex;
  justify-content: flex-end;
}
.upload button {
  background-color:#DC3545;
  color:#fff;
  font-weight: bolder;
  padding:10px 15px;
  border:none;
  outline:none;
  transition: all 0.5;
}
.upload button:hover{
  outline:none;
  border:none;
  opacity: 0.8;
}
.likeAnddislike{
  display: flex;
  justify-content: flex-end;
}
.debate {
  padding-left: 12%;
  padding-bottom: 7em;
  padding-right: 12%;
}

.iconify {
  font-size: 28px;
  line-height: 1em;
  color: #fff;
  padding: 6px;
  border:none;
}
.iconify:hover, .iconify:focus, .iconify:active{
  border:none;
}

.dislike {
  background: #f4a639;
}

.like {
  background: #60e083;
}

.img-responsive {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:10px 20px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.download_btn:hover{
  outline:none;
  background-color: rgb(235, 41, 41);
  color:#fff;
  border:1px solid  rgb(235, 41, 41);
}
@media screen and (max-width:1000px) {
  .debate {
    padding-left: 5%;
    padding-bottom: 4em;
    padding-right: 5%;
  }
}
@media screen and (max-width:768px) {

}
</style>