<template>
  <div class="container-fluid related_debates">
    <div class="row pt-5">
      <div class="col-md-6 col-lg-6 card_container" v-for="(debate, key) in trendingDebates" :key="key">
        <img
          :src="`${url()}${debate.pic}`"
          class="img-responsive"
          alt =""
        />
        <small class="py-3">Published by {{debate.author}}
        </small>

        <h5 class="py-2"><b>Thesis</b></h5>
        <p>{{debate.body && debate.body.slice(0,400)}} ... <router-link :to="`debates/${debate.id}`">read more</router-link>
        </p>
        <div class="row align-items-center  justify-content-center">

          <div class="col-lg-12 col-md-12 col-12">
              <div class="">
                <button @click="addLeadOpinion(debate.id)" class="download_btn">
                Add Lead Opinion
              </button>
              </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-4 likeAnddislike">
            <button class="iconify like mr-3" >
              <i class="far fa-thumbs-up"></i>
            </button>

            <button
              class="iconify dislike"
            >
            <i class="far fa-thumbs-down"></i>
            </button>
          </div> -->
        </div>
        </div>
      </div>
    <!-- <div class="row pt-5">
      <div class="col-md-12 text-center">
        <button class="btn view_more">View more</button>
      </div>
    </div> -->

  </div>
</template>

<script>
import debateAuthors from '../../../helpers/debates'
import { mapGetters } from 'vuex'
import url from '../../../helpers/url'

export default {
    data() {
    return {
      trendingDebates: []
    }
  },

  methods: {
    url() {
      return url();
    },
    addLeadOpinion(id){
      if(!this.token) {
                  return  this.$router.replace(`/redirect?service=login`)
                }  
      this.$router.replace(`/debates/${id}/add_lead_opinion`)
                 
    },
    fetchDebates() {
      this.$store.dispatch('fetchApprovedDebateArticle')
          .then( response => {

              const data = response.data
                
              this.trendingDebates = data
                
          })
          .catch(err => {
              
              if(err?.response?.data == undefined) {
              this.toast({
                  message:'Network error. Check network connection and try again.',
                  type:'error'
              })
          }
          })
    }

  },
  computed: {
    ...mapGetters(['token'])
  },
  created() {
    this.fetchDebates()
  }
};
</script>

<style scoped>
.likeAnddislike{
  display: flex;
  justify-content: flex-end;
}
.img-responsive {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
.iconify {
  font-size: 38px;
  line-height: 1em;
  color: #fff;
  padding: 6px;
}

.dislike {
  background: #f4a639;
}

.like {
  background: #60e083;
}
.iconify {
  font-size: 28px;
  line-height: 1em;
  color: #fff;
  padding: 6px;
  border:none;
}
.view_more {
  border: 1px solid rgba(0, 0, 0, 0.7);
  outline: none;
  transition: all 0.5s;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:10px 20px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.download_btn:hover {
  outline: none;
  background-color: rgb(235, 41, 41);
  color: #fff;
  border: 1px solid rgb(235, 41, 41);
}
.more {
  color: #8c8c8c;
}
@media screen and (max-width:768px) {
  .likes_dislikes{
    justify-content: space-between;
  }
    div.card_container:not(:last-of-type){
    margin-bottom:3em;
  }
}
</style>